import { useEffect } from 'react';
import clsx from 'clsx';
import { unescape } from 'html-escaper';

import ArrowDown from '@public/icons/angle-down.svg';
import Clipboard from '@public/icons/clipboard.svg';
import Markdown from 'components/common/MarkdownV2';
import { FAQProps } from 'interfaces/cms/content';
import styles from './FaqItem.module.scss';
import { useRouter } from 'next/router';

interface FaqItemProps extends FAQProps {
  openFaqIds: Array<number>;
  tabNum?: number;
  onOpenFaq: (id: number) => void;
}

function FaqItem({
  id,
  title,
  customEditor,
  boxBackground = '#fff',
  titleColor = '#000000',
  openFaqIds,
  onOpenFaq,
  tabNum = 0,
}: Readonly<FaqItemProps>) {
  const { locale } = useRouter();
  const hasOpenFaq = openFaqIds.some((openId) => openId === id);

  const scrollToFaq = (faqIdParam: number) => {
    if (faqIdParam === id) {
      const element = document.getElementById(`faq_item_${faqIdParam}`);
      const headerOffset = 200;
      const elementPosition = element?.getBoundingClientRect().top;
      if (elementPosition === undefined) return;
      if (!window) return;
      const offsetPosition = elementPosition + window.scrollY - headerOffset;
      if (element !== null) {
        scrollTo({
          top: offsetPosition,
          behavior: 'smooth',
        });
      }
    }
  };

  useEffect(() => {
    const query = new URLSearchParams(document.location.search);
    const faqIdParam = Number(query.get('faq_id'));
    scrollToFaq(faqIdParam);
  });

  return (
    <div
      id={`faq_item_${id}`}
      className={clsx(
        'rounded-[0.625rem] mb-[0.625rem]',
        'shadow-[0_7px_15px_rgba(42,49,203,0.1)]',
        'px-[1rem] py-[0.5rem]'
      )}
      style={{ backgroundColor: boxBackground }}
    >
      <div
        className="flex flex-row items-center justify-between py-[0.625rem] cursor-pointer text-[14px] leading-[20px] md:text-[16px] font-bold"
        onClick={() => onOpenFaq(id)}
      >
        <Markdown md={title} textAlign="left" textColor={titleColor} />
        <div className="ml-3 flex flex-row items-center">
          <Clipboard
            className="mr-[0.8125rem]"
            fill={locale === 'th-TH' ? '#58b8e0' : '#005098'}
            onClick={() => {
              navigator.clipboard.writeText(
                `${location.protocol}${location.host}${location.pathname}?tab_num=${tabNum}&faq_id=${id}`
              );
            }}
          />

          <ArrowDown
            className={clsx(
              'fill-primaryColor',
              'transition-all ease-in-out duration-300',
              hasOpenFaq ? 'rotate-180' : 'rotate-0'
            )}
          />
        </div>
      </div>

      <div
        className={clsx(
          'transition-all ease-in-out overflow-hidden',
          hasOpenFaq ? 'duration-500' : 'max-h-0 duration-300',
          'z-0 text-[14px] leading-[20px] md:text-[16px] md:leading-[24px] first:overflow-initial'
        )}
      >
        {customEditor && (
          <div
            className={clsx(
              'py-[0.625rem] px-2 border-t border-dashed border-grayColor text-[#4e4b64]',
              styles.customEditor
            )}
            dangerouslySetInnerHTML={{
              __html: unescape(customEditor),
            }}
          />
        )}
      </div>
    </div>
  );
}

export default FaqItem;
